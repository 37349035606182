import "./SideBarMenuCardView.scss"

import { SideBarMenuCard } 	from "../../../types/types";
import { classNames } 		from "../../../utils/classes";





interface SideBarMenuCardViewProps {
	card: SideBarMenuCard;
	isOpen: boolean;
}


export default function SideBarMenuCardView({
	card,
	isOpen
}:SideBarMenuCardViewProps){
	return <div	className	= "SideBarMenuCardView">
		<div	className	= {classNames('profileInfo', isOpen? '': 'collapsed')}>
			<div	className	= "name">{card.displayName}</div>
			<div	className	= "title">{card.title}</div>
		</div>
	</div>;
}