import { Formik }				from 'formik';
import *				as Yup	from "yup";

import Row						from 'react-bootstrap/Row';
import Col						from 'react-bootstrap/Col';
import Form						from 'react-bootstrap/Form';
import FloatingLabel			from 'react-bootstrap/FloatingLabel';
import Button					from 'react-bootstrap/Button';

import { useState }	from 'react'





export function FormUsersModify( props:{email:any, filter:any}){
	const validate = Yup.object({
		rol: Yup.string().required('Required'),
	}).defined();

	const [update, setUpdate] = useState([])

	return (
		<div>
			<Formik
				initialValues	= {{
					email: props.email,
					rol: undefined,
				}}

				validationSchema	= {validate}

				onSubmit	= {(values)	=> {
					fetch(
						process.env.REACT_APP_API_URL + "/update_user/", {
																			mode: 'no-cors',
																			'method': 'POST',
																			headers: {
																				'Content-Type': 'applications/json',
																				'Accept': 'application/json',
																				'Access-Control-Allow-Origin': '*',
																				'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
																				'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
																			},
																			body: JSON.stringify(values)
																		}
					)
					.then(	resp	=> resp.json())
					.then(	resp	=> {setUpdate(resp)})
					.catch(	error	=> console.error(error))
					.finally(refreshpage)
				}}
			>
				{
					({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						errors,
						isSubmitting
					}) 	=> (
						<Form noValidate onSubmit	= {handleSubmit}>
							<Row>
								<Form.Group as	= {Col} md	= "12" controlId	= "validationFormik01">
									<Form.Label><span>*</span>Seleccione el rol que desea asignar</Form.Label>
									<Form.Group as	= {Col} md	= "12">
										<FloatingLabel controlId	= "rol" label	= "Rol">
											<Form.Select
												name		= "rol"
												onChange	= {handleChange}
												onBlur 		= {handleBlur}
												required
											>
												<option selected>Seleccione el rol</option>
												<option	value	= "Usuario"			disabled	= {props.filter === "Usuario"}>Usuario</option>
												<option	value	= "Recepcionista"	disabled	= {props.filter === "Recepcionista"}>Recepcionista</option>
												<option	value	= "Admin"			disabled	= {props.filter === "Admin"}>Admin</option>
												<option	value	= "SuperAdmin"		disabled	= {props.filter === "SuperAdmin"}>SuperAdmin</option>
											</Form.Select>
										</FloatingLabel>
									</Form.Group>

									{errors.rol && (<Form.Label	className	= 'Alert'>Ingrese el rol al usuario</Form.Label>)}
								</Form.Group>
								<Form.Group as	= {Col}>
									<Button	type	= "submit"	disabled	= {
																				isSubmitting ||
																				!!(errors.rol)
																			}
									> Modificar </Button>
								</Form.Group>
							</Row>
						</Form>
					)
				}
			</Formik>
		</div>
	)
}

const refreshpage = ()	=> {window.location.reload()}