import TablesUsers from '../complements/tables/tables_users'





export default function Adminitracion() {
	if(localStorage.getItem('rol') === "SuperAdmin"){
		return (
			<>
				<div	className	= 'caja_secundaria'>
					<h1>Administración</h1>
					<TablesUsers/>
				</div>
			</>
		);
	} else {
		return (
			<>
				<div	className	= 'caja_secundaria'>
					<h3>¡NO TIENE PERMISO DE INGRESO A ESTA OPCION!</h3>
				</div>
			</>
		);
	}
}