import { FormUsersModify }		from '../forms/forms_users';

import Table 					from '@mui/material/Table';
import TableBody 				from '@mui/material/TableBody';
import TableCell 				from '@mui/material/TableCell';
import TableContainer 			from '@mui/material/TableContainer';
import TableHead				from '@mui/material/TableHead';
import TableRow 				from '@mui/material/TableRow';
import Paper 					from '@mui/material/Paper';
import Button					from '@mui/material/Button';
import Dialog					from '@mui/material/Dialog';
import DialogActions			from '@mui/material/DialogActions';
import DialogContent			from '@mui/material/DialogContent';
import Slide					from '@mui/material/Slide';
import { TransitionProps }		from '@mui/material/transitions';
import Skeleton				    from '@mui/material/Skeleton';
import Stack					from '@mui/material/Stack';
import BottomNavigationAction	from '@mui/material/BottomNavigationAction';

import * 			as React 	from 'react';
import { useState, useEffect }	from 'react'
import { TbEdit, TbEditOff }	from "react-icons/tb"





export default function TablesUsers() {
	const [data, setData] = useState([])

	useEffect(()	=> {
			fetch(
				process.env.REACT_APP_API_URL + "/consult_user/", {
																		'method': 'GET',
																		headers: {'Content-Type': 'applications/json'}
																	}
			)
			.then(	resp	=> resp.json())
			.then(	resp	=> {setData(resp)})
			.catch(	error	=> console.error(error))
	}, [])

	if(data.length > 0){
		return (
			<>
				<h2>Super Admin</h2>
				<TableContainer	component   = {Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell	align	= 'center'	padding	= 'checkbox'>N°</TableCell>
								<TableCell>Usuario</TableCell>
								<TableCell>Correo</TableCell>
								<TableCell	align	= 'center'	padding	= 'checkbox'>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row,	index)	=> (<Row	key	= {index}	index	= {index}	row	= {row}	filter	= "SuperAdmin"/>))}
						</TableBody>
					</Table>
				</TableContainer>

				<h2>Admin</h2>
				<TableContainer	component	= {Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell	align	= 'center'	padding	= 'checkbox'>N°</TableCell>
								<TableCell>Usuario</TableCell>
								<TableCell>Correo</TableCell>
								<TableCell	align	= 'center'	padding	= 'checkbox'>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row,	index)	=> (<Row	key	= {index}	index	= {index}	row	= {row}	filter	= "Admin" />))}
						</TableBody>
					</Table>
				</TableContainer>

				<h2>Recepcionista</h2>
				<TableContainer	component	= {Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell	align	= 'center'	padding	= 'checkbox'>N°</TableCell>
								<TableCell>Usuario</TableCell>
								<TableCell>Correo</TableCell>
								<TableCell	align	= 'center'	padding	= 'checkbox'>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row,	index)	=> (<Row	key	= {index}	index	= {index}	row	= {row}	filter	= "Recepcionista"/>))}
						</TableBody>
					</Table>
				</TableContainer>

				<h2>Usuario</h2>
				<TableContainer	component	= {Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell	align	= 'center'	padding	= 'checkbox'>N°</TableCell>
								<TableCell>Usuario</TableCell>
								<TableCell>Correo</TableCell>
								<TableCell	align	= 'center'	padding	= 'checkbox'>Editar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row,	index)	=> (<Row	key	= {index}	index	= {index}	row	= {row}	filter	= "Usuario"/>))}
						</TableBody>
					</Table>
				</TableContainer>
			</>
		);
	} else {
		return  (
			<div>
				<Stack spacing  = {.5}>
					<Skeleton variant	= "rounded"  height	= {30} />
					<Skeleton variant	= "rounded"  height	= {40} />
				</Stack>
				<p	className	= 'center_note'>No se ha obtenido una respuesta satisfactoria por el servidor, espere un momento por favor</p>
			</div>
		)
	}
}









function createData(
	email: string,
	nombre: string,
	rol: string
){
	return {
		email:email,
		nombre:nombre,
		rol:rol
	};
}

function	Row(props: {	index:any,	row: ReturnType<typeof createData>,	filter:string }) {
	const {	row,	index,	filter }	= props;

	const [openModify, setOpenModify]	= React.useState(false);
	const handleClickOpenModify		= ()	=> {setOpenModify(true);};
	const handleCloseModify			= ()	=> {setOpenModify(false);};


	if (row.rol ===	filter){
		return (
			<React.Fragment >
				<TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
					<TableCell	align	= 'center'	padding	='checkbox'>{index + 1}</TableCell>
					<TableCell>{row.nombre}</TableCell>
					<TableCell	align	= 'center'	padding	= 'checkbox'>{row.email}</TableCell>
					<TableCell	align	= 'center'	padding	= 'checkbox'>
						{
							row.email === localStorage.getItem('email') ?
							<TbEditOff />
							:
							<BottomNavigationAction icon = {<TbEdit />} onClick = {handleClickOpenModify}/>
						}
					</TableCell>
				</TableRow>

				<Dialog
					open				= {openModify}
					TransitionComponent	= {Transition}
					keepMounted
					onClose			= {handleCloseModify}
					aria-describedby	= "alert-dialog-slide-description"
				>
					<div  	className = 'SuperDialog'>
						<h4>¿Desea cambiar el rol de <span>{row.email}</span>?</ h4>
						<DialogContent><FormUsersModify email	= {row.email}	filter	= {filter}/></DialogContent>
						<DialogActions><Button onClick	= {handleCloseModify}	className	= "cancel">x</Button></DialogActions>
					</div>
				</Dialog>
			</React.Fragment>
		);
	} else {
		return (<></>)
	}

}

const Transition = React.forwardRef(function Transition(props: TransitionProps & {children: React.ReactElement<any, any>;}, ref: React.Ref<unknown>, ) {
	return <Slide direction="up" ref={ref} {...props} />;
});