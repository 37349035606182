import { Formik }				from 'formik';
import *				as Yup	from "yup";

import Row						from 'react-bootstrap/Row';
import Col						from 'react-bootstrap/Col';
import Form						from 'react-bootstrap/Form';
import FloatingLabel			from 'react-bootstrap/FloatingLabel';
import Button					from 'react-bootstrap/Button';

import Skeleton					from '@mui/material/Skeleton';
import Stack					from '@mui/material/Stack';

import { useState, useEffect }	from 'react'





export default function FormAgendamiento(){
	const [days_reservation,	setDays]	= useState({'dias': [], 'pisos': [],'espacios': [], 'parqueaderos':[]})
	const dias			= days_reservation["dias"]			|| []
	const pisos			= days_reservation["pisos"]			|| []
	const espacios		= days_reservation["espacios"]		|| []
	const parqueaderos	= days_reservation["parqueaderos"]	|| []
	
	const [create,	setCreate]	= useState([])

	const validate = Yup.object({
		idAgendamiento:	Yup.number().required('Required'),
		piso:			Yup.number().required('Required'),
		espacio:		Yup.number().required('Required'),
		fecha:			Yup.string().required('Required'),
		horaInicio:		Yup.string().required('Required'),
		horaFin:		Yup.string().required('Required')

	}).defined();

	useEffect(()	=> {
		fetch(
			process.env.REACT_APP_API_URL + "/working_days/", {
																	'method': 'POST',
																	headers: {'Content-Type': 'applications/json'},
																	body: JSON.stringify({"email":localStorage.getItem('email')})
																}
		)
		.then(  resp	=> resp.json())
		.then(  resp	=> {setDays(resp)})
		.catch(	error	=> console.error(error))
	}, [])

	if(dias.length === 0 && pisos.length === 0 && espacios.length === 0){
		return  (
			<div>
				<Stack spacing	= {1}>
					<Row>
						<Form.Group	as	= {Col}	md	= "6"></Form.Group>
							<Skeleton	variant	= "rounded"	height	= {40}	/>
							<Skeleton	variant	= "rounded"	height	= {40}	/>
							<Row>
								<Form.Group	as	= {Col}	md	= "6"><Skeleton	variant	= "rounded"	height	= {40}/></Form.Group>
								<Form.Group	as	= {Col}	md	= "6"><Skeleton	variant	= "rounded"	height	= {40}/></Form.Group>
							</Row>
						<Form.Group	as	= {Col}	md	= "6">
							<Skeleton	variant	= "rounded"	height	= {40}	/>
							<Skeleton	variant	= "rounded"	height	= {40}	/>
							<Skeleton	variant	= "rounded"	height	= {40}	/>
						</Form.Group>
						<Form.Group	as	= {Col}	md	= "12"><Skeleton	variant	= "rounded"	height	= {40}/></Form.Group>
					</Row>
				</Stack>
				<p	className	= 'center_note'>No se ha obtenido una respuesta satisfactoria por el servidor, espere un momento por favor</p>
			</div>
		)
	}
	else if(dias.length === 0 ){	return (<h2>NO HAY DIAS DISPONIBLES PARA RESERVAR</h2>)}
	else if(pisos.length === 0 ){	return (<h2>NO HAY PISOS DISPONIBLES PARA RESERVAR</h2>)}
	else if(espacios.length === 0 ){	return (<h2>NO HAY ESPACIOS DISPONIBLES PARA RESERVAR</h2>)}
	else {
		return (
				<div>
					<Formik
						initialValues	= {{
							idAgendamiento:		Math.floor(((new Date()).getTime() - (new Date(1970, 1, 1, 0, 0, 0, 0)).getTime()) / 1000),
							nombreColaborador:	localStorage.getItem('displayName'),
							correo:				localStorage.getItem('email'),
							fecha:				undefined,
							horaInicio:			"00:01",
							horaFin:			"23:59",
							piso:				undefined,
							espacio:			undefined,
							parqueadero:		undefined,
						}}

						validationSchema	= {validate}

						onSubmit	= {(values)	=> {
							fetch(
								process.env.REACT_APP_API_URL + "/create_scheduling/", {
																							'method': 'POST',
																							headers: {'Content-Type': 'applications/json'},
																							body: JSON.stringify(values)
																						}
							)
								.then(	resp	=> resp.json())
								.then(	resp	=> {setCreate(resp)})
								.catch(	error	=> console.error(error))
								.finally(refreshpage)
						}}
					>
						{
							({
								handleSubmit,
								handleChange,
								handleBlur,
								values,
								errors,
								isSubmitting
							}) 	=> (
									<Form	noValidate	onSubmit	= {handleSubmit}>
										<ul>
											<li>Para realizar su agendamiento, debe de completar los campos obligatorios</li>
											<li>El espacio de parqueadero es opcional, solo complete si desea agendar el espacio de parqueadero</li>
										</ul>

										<Row>
											<Form.Group	as	= {Col}	md	= "5">
												<Row>
													<Form.Group	as	= {Col}	md	= "12">
														<Form.Label><span>*</span>Fecha de la reserva</Form.Label>
														
														<FloatingLabel controlId	= "fecha" label	= "Fecha">
															<Form.Select
																name		= "fecha"
																onChange	= {handleChange}
																onBlur		= {handleBlur}
																required
															>
																<option selected>Seleccione una fecha</option>
																{dias.map((row, index)	=> (<Row_days	key	= {index}	row	= {row}	/>))}
															</Form.Select>
														</FloatingLabel>
													</Form.Group>
												</Row>
											</Form.Group>

											<Form.Group	as	= {Col}	md	= "7">
												<Row>
													<Form.Group as	= {Col} md	= "6">
														<Form.Label><span>*</span>Piso a reservar </Form.Label>
														<FloatingLabel controlId	= "piso" label	= "Piso">
															<Form.Select
																name		= "piso"
																onChange	= {handleChange}
																onBlur		= {handleBlur}
																disabled	= {values.fecha === undefined}
																required
															>
																{pisos.map((row, index)	=> (<Row_floors	key	= {index}	row	= {row}	/>))}
															</Form.Select>
														</FloatingLabel>
													</Form.Group>

													<Form.Group as	= {Col} md	= "6">
														<Form.Label><span>*</span>Puesto a reservar</Form.Label>
														<FloatingLabel controlId	= "espacio" label	= "Puesto">
															<Form.Select
																name		= "espacio"
																onChange	= {handleChange}
																onBlur		= {handleBlur}
																disabled	= {values.piso === undefined}
																required
															>
																{espacios.map((row, index)	=> (<Row_positions	key	= {index}	row	= {row}	piso = {values.piso}	fecha = {values.fecha}/>))}

															</Form.Select>
														</FloatingLabel>
													</Form.Group>


													<Form.Group	as	= {Col}	md	= "12">
														<Form.Label>¿Que espacio de parqueadero desea reservar?</Form.Label>
														<FloatingLabel controlId	= "parqueadero" label	= "Vehiculo">
															<Form.Select
																name		= "parqueadero"
																onChange	= {handleChange}
																onBlur		= {handleBlur}
																disabled	= {values.fecha === undefined || values.piso === undefined || values.espacio === undefined}
																required
															>
																<option selected value	= ''>Seleccione un espacio</option>
																{parqueaderos.map((row, index)	=> (<Row_vehicle	key	= {index}	row	= {row}	fecha = {values.fecha}/>))}

															</Form.Select>
														</FloatingLabel>
													</Form.Group>
												</Row>
											</Form.Group>

											<Form.Group as	= {Col} md	= "12">
												<div	className = "caja-alerta">													
													{errors.fecha	&&	(<Form.Label	className	= 'Alert'>FECHA DE AGENDAMIENTO</Form.Label>)}
													{errors.piso	&&	(<Form.Label	className	= 'Alert'>PISO PARA AGENDAR</Form.Label>)}
													{errors.espacio	&&	(<Form.Label	className	= 'Alert'>PUESTO PARA AGENDAR</Form.Label>)}
												</div>

												<Button	type	= "submit"	disabled	= {
														isSubmitting		||
														!!(errors.piso)		||	!!(errors.espacio)		||
														!!(errors.fecha)	||	!!(errors.horaInicio)	||
														!!(errors.horaFin)
													}
												> AGENDAR </Button>
											</Form.Group>

										</Row>
									</Form>
							)
						}
					</Formik>
				</div>
		)
	}
}





export function FormAgendamientoModify( props:{idAgendamiento:any, estado:any}){
	const [create, setCreate] = useState([])

	return (
		<div>
			<Formik
					initialValues={{
						idAgendamiento:	props.idAgendamiento,
						estado:			props.estado,
					}}

					onSubmit={(values)	=> {
						fetch(
							process.env.REACT_APP_API_URL + "/update_scheduling/", {
								mode: 'no-cors',
								'method': 'POST',
								headers: {
									'Content-Type': 'applications/json',
									'Accept': 'application/json',
									'Access-Control-Allow-Origin': '*',
									'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
									'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
								},
								body: JSON.stringify(values)
							}
						)
						.then(  resp	=> resp.json())
						.then(	resp	=> {setCreate(resp)})
						.catch(	error	=> console.error(error))
						.finally(refreshpage)
					}}
			>
				{
					({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						errors,
						isSubmitting
					}) 	=> (
						<Form noValidate onSubmit={handleSubmit}>
							<Row>
								<Form.Group	as	= {Col}>
									<Button	type="submit"> Modificar </Button>
								</Form.Group>
							</Row>
						</Form>
					)
				}
			</Formik>
		</div>
	)
}




function createDataParking(
	dia: string,
	idParqueadero: number,
	nombre: string,
	capacidad: number,
	ocupacion: number
) {
  return {
		dia: dia,
		idParqueadero: idParqueadero,
		nombre: nombre,
		capacidad: capacidad,
		ocupacion: ocupacion

	};
}

function createDataSpace(
	dia: string,
	idEspacio: number,
	nombreEspacio: string,
	capacidad: number,
	ocupacion: number,
	piso: number
) {
	return {
		dia: dia,
		idEspacio: idEspacio,
		nombreEspacio: nombreEspacio,
		capacidad: capacidad,
		ocupacion: ocupacion,
		piso: piso
	};
}

function createDataFloors(
	piso: any,
	estado: any,
	fecha: any
) {
  return {
		piso: piso,
		estado: estado,
		fecha: fecha
	};
}

function createDataDays(
	dia: string,
	nombre: string
) {
  return {
		dia: dia,
		nombre: nombre
	};
}


function Row_floors(props: { row: ReturnType<typeof createDataFloors>}) {
	const { row }	= props;

	if (row.estado === "True"){	return (<option	value	= {row.piso}>{row.piso}</option>);
	} else {					return (<option	className	= 'void'></option>)}


}

function Row_positions(props: { row: ReturnType<typeof createDataSpace>, piso:any, fecha:any }) {
	const { row, piso, fecha }	= props;

	let pisoN = +piso

	if(row.piso === pisoN) {
		if(row.dia === fecha) {
			if((row.capacidad - row.ocupacion) > 0){
				return (<option value	= {row.idEspacio}>{row.nombreEspacio}:	Disponibles({row.capacidad - row.ocupacion} de {row.capacidad})</option>);

			} else {	return (<option	value = "" disabled>{row.nombreEspacio}:  Sin espacio disponible</option>)}
		} else {	return (<option	className	= 'void' selected></option>)}
	} else {	return (<option	className	= 'void' selected></option>)}
}

function Row_vehicle(props: { row: ReturnType<typeof createDataParking>, fecha:any}) {
	const { row, fecha }	= props;

	if(row.dia === fecha) {
		if((row.capacidad - row.ocupacion) > 0){
			return (<option value	= {row.idParqueadero}>{row.nombre}:	Disponibles({row.capacidad - row.ocupacion} de {row.capacidad})</option>);

		} else {	return (<option	value = "" disabled>{row.nombre}:  Sin espacio disponible</option>)}
	} else {	return (<option	className	= 'void' selected></option>)}	
}

function Row_days(props: { row: ReturnType<typeof createDataDays> }) {
	const { row }	= props;
	return (<option  value	= {row.dia}>{row.dia}	- {row.nombre}</option>);
}

const refreshpage = ()	=> {window.location.replace("/MisReservas")}