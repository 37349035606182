import "./SideBarMenu.scss"

import SideBarMenuItemView						from "./SideBarMenuItemView"
import SideBarMenuCardView						from "./SideBarMenuCardView"

import { VscMenu }								from "react-icons/vsc"


import { SideBarMenuCard, SideBarMenuItem }		from "../../../types/types"
import {	classNames }							from "../../../utils/classes"

import { GoCalendar , GoClippy, GoGithubAction}	from "react-icons/go"
import { TbParking }							from "react-icons/tb"
import { CgToday }								from "react-icons/cg"
import { BsGear }								from "react-icons/bs"
import { RiAdminLine }							from "react-icons/ri"
import { TbReportSearch }						from "react-icons/tb"
import { MdLogout }								from "react-icons/md"

import { useState, useEffect }					from 'react'

import { InteractionType }						from "@azure/msal-browser";
import { useMsalAuthentication , useMsal}		from "@azure/msal-react";






const itemUsuario:SideBarMenuItem[] = [
	{
		label: "Agendamiento",
		tooltip: "Programa tus proximas visitas a la fabrica de sueños",
		icon: GoCalendar,
		url: "/Agendamiento",
		classtyle: "User",
	},
	{
		label: "Parqueadero",
		tooltip: "Programa tus proximas visitas al parqueadero de la fabrica de sueños",
		icon: TbParking,
		url: "/Parqueadero",
		classtyle: "SuperAdmin",
	},
	{
		label: "Mis reservas",
		tooltip: "Consulta tus reservas programadas en nuestra fabrica de sueños",
		icon: GoClippy,
		url: "/MisReservas",
		classtyle: "User",

	}
];

const itemsRecepcionista:SideBarMenuItem[] = [
	{
		label: "Agendamiento",
		tooltip: "Programa tus proximas visitas a la fabrica de sueños",
		icon: GoCalendar,
		url: "/Agendamiento",
		classtyle: "Recepcionista",
	},
	{
		label: "Parqueadero",
		tooltip: "Programa tus proximas visitas al parqueadero de la fabrica de sueños",
		icon: TbParking,
		url: "/Parqueadero",
		classtyle: "SuperAdmin",
	},
	{
		label: "Mis reservas",
		tooltip: "Consulta tus reservas programadas en nuestra fabrica de sueños",
		icon: GoClippy,
		url: "/MisReservas",
		classtyle: "Recepcionista",
	},
	{
		label: "Reservas del dia",
		tooltip: "Puedes consultar las reservas programadas del dia de hoy en nuestra fabrica de sueños",
		icon: CgToday,
		url: "/ReservasDelDia",
		classtyle: "Recepcionista",
	},
	{
		label: "Reservas de salas",
		tooltip: "Puedes consultar y/o ejecutar las reservas programadas de salas de hoy en nuestra fabrica de sueños",
		icon: CgToday,
		url: "/ReservasSalas",
		classtyle: "Recepcionista",
	},
];

const itemsAdmin:SideBarMenuItem[] = [
	{
		label: "Agendamiento",
		tooltip: "Programa tus proximas visitas a la fabrica de sueños",
		icon: GoCalendar,
		url: "/Agendamiento",
		classtyle: "Admin",
	},
	{
		label: "Parqueadero",
		tooltip: "Programa tus proximas visitas al parqueadero de la fabrica de sueños",
		icon: TbParking,
		url: "/Parqueadero",
		classtyle: "SuperAdmin",
	},
	{
		label: "Mis reservas",
		tooltip: "Consulta tus reservas programadas en nuestra fabrica de sueños",
		icon: GoClippy,
		url: "/MisReservas",
		classtyle: "Admin",
	},
	{
		label: "Reservas del dia",
		tooltip: "Puedes consultar las reservas programadas del dia de hoy en nuestra fabrica de sueños",
		icon: CgToday,
		url: "/ReservasDelDia",
		classtyle: "Admin",
	},
	{
		label: "Reservas de salas",
		tooltip: "Puedes consultar y/o ejecutar las reservas programadas de salas de hoy en nuestra fabrica de sueños",
		icon: CgToday,
		url: "/ReservasSalas",
		classtyle: "Admin",
	},
	{
		label: "Configuración",
		tooltip: "Configuración de pisos, ocupación y parqueadero",
		icon: TbReportSearch,
		url: "/Configuracion",
		classtyle: "Admin",
	},
	{
		label: "Reportes",
		tooltip: "Reportes de agendamiento de la fabrica de sueños",
		icon: BsGear,
		url: "/Reportes",
		classtyle: "Admin",
	}
];

const itemsSuperAdmin:SideBarMenuItem[] = [
	{
		label: "Agendamiento",
		tooltip: "Programa tus proximas visitas a la fabrica de sueños",
		icon: GoCalendar,
		url: "/Agendamiento",
		classtyle: "SuperAdmin",
	},
	{
		label: "Parqueadero",
		tooltip: "Programa tus proximas visitas al parqueadero de la fabrica de sueños",
		icon: TbParking,
		url: "/Parqueadero",
		classtyle: "SuperAdmin",
	},
	{
		label: "Mis reservas",
		tooltip: "Consulta tus reservas programadas en nuestra fabrica de sueños",
		icon: GoClippy,
		url: "/MisReservas",
		classtyle: "SuperAdmin",
	},
	{
		label: "Reservas del dia",
		tooltip: "Puedes consultar las reservas programadas del dia de hoy en nuestra fabrica de sueños",
		icon: CgToday,
		url: "/ReservasDelDia",
		classtyle: "SuperAdmin",
	},
	{
		label: "Reservas de salas",
		tooltip: "Puedes consultar y/o ejecutar las reservas programadas de salas de hoy en nuestra fabrica de sueños",
		icon: CgToday,
		url: "/ReservasSalas",
		classtyle: "SuperAdmin",
	},
	{
		label: "Configuración",
		tooltip: "Configuración de pisos, ocupación y parqueadero",
		icon: BsGear,
		url: "/Configuracion",
		classtyle: "SuperAdmin",
	},
	{
		label: "Reportes",
		tooltip: "Reportes de agendamiento de la fabrica de sueños",
		icon: TbReportSearch,
		url: "/Reportes",
		classtyle: "SuperAdmin",
	},
	{
		label: "Administración",
		tooltip: "Administración de roles de usuarios para la app de agendamiento de nuestra fabrica de sueños",
		icon: RiAdminLine,
		url: "/Administracion",
		classtyle: "SuperAdmin",
	}
];



export function SideBarMenu(){

	const [isOpen, setIsOpen]		= useState<boolean>(true);
	const [graphData, setGraphData]	= useState(null);
	const [users, setUsers]			= useState([])

	const { instance } = useMsal();

	const handleSignOut = ()	=> { instance.logoutRedirect(); }

	function handleClick() { setIsOpen(!isOpen)}

    const { result,	error } = useMsalAuthentication(InteractionType.Popup, {scopes: ["user.read"]});


	useEffect(()	=> {
        if (!!graphData) {return;}

        if (!!error) {return;}

        if (result) {
            const { accessToken }	= result;
			const headers 			= new Headers();
			const bearer 			= `Bearer ${accessToken}`;

			headers.append("Authorization", bearer);
		
			const options = {
				method: "GET",
				headers: headers
			};
		
			fetch('https://graph.microsoft.com/v1.0/me', options)
				.then(response	=> response.json())
                .then(response	=> setGraphData(response))
				.catch(	error	=> console.error(error));
        }
    }, [graphData,	error, result]);

	if (graphData && users.length === 0){
		localStorage.setItem('displayName', graphData['displayName']);
		localStorage.setItem('email', graphData['mail']);

		fetch(
				process.env.REACT_APP_API_URL + "/create_user/", {
																	mode: 'no-cors',
																	'method': 'POST',
																	headers: {'Content-Type': 'applications/json'},
																	body: JSON.stringify({"email":graphData['mail'],"nombre":graphData['displayName']})
																}
		)
		.then(  resp	=> resp.json())
		.catch(	error	=> console.error(error))

		fetch(
			process.env.REACT_APP_API_URL + "/consult_user/" + localStorage.getItem('email'), {
																										'method': 'GET',
																										headers: {'Content-Type': 'applications/json'},
																									}
		)
		.then(  resp	=> resp.json())
		.then(  resp	=> {setUsers(resp)})
		.catch(	error	=> console.error(error))
	}
	if (graphData && users.length > 0){
		const card:SideBarMenuCard = {
			rol:			users[0]['rol'],
			displayName:	graphData['displayName'],
			title:			graphData['jobTitle'],
			photoUrl:		"",
			email:			graphData['mail'],
		};
	
		localStorage.setItem('rol', card.rol);
	
		return (
			<div	className	= {classNames("SideBarMenu", isOpen ? "expanded": "collapse show")}>
				<div	className	= {classNames("fixMenu", isOpen ? "expanded": "collapse show")}>
					<div	className	= "menuButton"><button	className	= "hamburgerIcon"	onClick	= {handleClick}><VscMenu/></button></div>
	
					<SideBarMenuCardView	card	= {card}	isOpen	= {isOpen}/>

					{card.rol === "Usuario"			?	<>{itemUsuario.map(			(itemUsuario)			=> (<SideBarMenuItemView	 key	= {itemUsuario.label}			item	= {itemUsuario}			isOpen	= {isOpen} />))}</>:""}
					{card.rol === "Recepcionista"	?	<>{itemsRecepcionista.map(	(itemsRecepcionista)	=> (<SideBarMenuItemView	 key	= {itemsRecepcionista.label}	item	= {itemsRecepcionista}	isOpen	= {isOpen} />))}</>:""}
					{card.rol === "Admin"			?	<>{itemsAdmin.map(			(itemsAdmin)			=> (<SideBarMenuItemView	 key	= {itemsAdmin.label}			item	= {itemsAdmin}			isOpen	= {isOpen} />))}</>:""}
					{card.rol === "SuperAdmin"		?	<>{itemsSuperAdmin.map(		(itemsSuperAdmin)		=> (<SideBarMenuItemView	 key	= {itemsSuperAdmin.label}		item	= {itemsSuperAdmin}		isOpen	= {isOpen} />))}</>:""}
				
					<div	className	= "SideBarMenuItemView">
						<a	className	= "exit"	onClick	= {handleSignOut}>
							<div	className	= {classNames('ItemContent', isOpen? '': 'collapsed')}>
								<div	className	= "icon"><MdLogout/></div>
								<span	className	= "label">Salir</span>
							</div>
						</a>
					</div>
				</div>
			</div>
			
		)
	} else {
		return (
			<div	className	= {classNames("SideBarMenu", isOpen ? "expanded": "collapse show")}>
				<div	className	= {classNames("fixMenu", isOpen ? "expanded": "collapse show")}>
					<div	className	= "SideBarMenuItemView">
						<a	className	= "exit"	onClick	= {handleSignOut}>
							<div	className	= {classNames('ItemContent', isOpen? '': 'collapsed')}>
								<div	className	= "icon"><MdLogout/></div>
								<span	className	= "label">Salir</span>
							</div>
						</a>
					</div>
				</div>
			</div>
		)
	}
}


const refreshpage = ()	=> {window.location.reload()}