import Table 			from '@mui/material/Table';
import TableBody 		from '@mui/material/TableBody';
import TableCell 		from '@mui/material/TableCell';
import TableContainer 	from '@mui/material/TableContainer';
import TableHead 		from '@mui/material/TableHead';
import TableRow 		from '@mui/material/TableRow';
import Paper 			from '@mui/material/Paper';
import Skeleton			from '@mui/material/Skeleton';
import Stack			from '@mui/material/Stack';
import Button			from '@mui/material/Button';


import * 			as React 	from 'react';
import { useState, useEffect } 	from 'react'





export default function CollapsibleTable() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(true); // Estado para rastrear si está cargando


	const fetchData = ()	=> {
		setLoading(true); // Activar el estado de carga al actualizar

		fetch(process.env.REACT_APP_API_URL + '/ConsultRooms/', {
			method: 'GET',
			headers: { 'Content-Type': 'applications/json' },
		})
			.then((resp)	=> resp.json())
			.then((resp)	=> {
				setData(resp);
				setLoading(false); // Desactivar el estado de carga al completar
			})
			.catch((error)	=> console.error(error));
	};

	useEffect(()	=> {fetchData();}, []);

	const updateData = ()	=> {
		setLoading(true); // Activar el estado de carga al actualizar

		fetch(process.env.REACT_APP_API_URL + '/UpdateRooms/', {
			method: 'GET',
			headers: { 'Content-Type': 'applications/json' },
		})
			.then((resp)	=> resp.json())
			.then((resp)	=> {
				setData(resp);
				setLoading(false); // Desactivar el estado de carga al completar
			})
			.catch((error)	=> console.error(error));
	};

	if (loading) {
		return (
			<div>
				<Stack spacing={0.5}>
					<Skeleton variant	= "rounded" height	= {50} />
					<Skeleton variant	= "rounded" height	= {50} />
					<Skeleton variant	= "rounded" height	= {50} />
				</Stack>
				<p	className	= "center_note">Cargando datos, por favor espere...</p>
			</div>
		);
	}

	if(data.length > 0){
		return (
				<>
					<Button
						variant	= "contained"
						color	= "primary"
						style	= {{ marginBottom: '10px' }}
						onClick	= {updateData}
					>
						Actualizar permisos
					</Button>
					<TableContainer component	= {Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align	= 'center' padding	= 'checkbox'>N°</TableCell>
									<TableCell>Sala</TableCell>
									<TableCell>Piso</TableCell>
									<TableCell>Agendamiento</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{data.map((row,	index)	=> (<Row	key	= {index}	index	= {index}	row	= {row}/>))}
							</TableBody>
						</Table>
					</TableContainer>
				</>
		);
	} else {
		return  (
			<div>
				<p	className	= 'center_note'>No se ha obtenido una respuesta de reservas del dia de hoy, vuelva a intentarlo</p>
			</div>
		)
	}
}



function	Row(props: {	index: any;	row: any }) {
	const {	row,	index } = props;
	return (
		<React.Fragment>
			<TableRow	sx	= {{ "& > *": { borderBottom: "unset" } }}>
				<TableCell>{index + 1}</TableCell>
				<TableCell>{row.nombre}</TableCell>
				<TableCell>{row.piso}</TableCell>
				<TableCell>
					{row.agendamiento.map((ag: any, idx: number)	=> (
						<ul
							key		= {idx}
							style	= {{
								borderBottom: idx !==	row.agendamiento.length - 1 ? '1px solid #cbc7c7' : 'none', 
								marginBottom: idx !==	row.agendamiento.length - 1 ? '10px' : '0px', 
								paddingBottom: idx !==	row.agendamiento.length - 1 ? '10px' : '0px'
							  }}
						>
							<li	style	= {{ width: '100%' }}>
								<span>Hora Inicio:</span> {ag.horaInicio} <span>Hasta:</span> {ag.horaFin}
							</li>
							<li	style	= {{ width: '100%' }}>
								<span>Responsable:</span> {ag.responsable}
							</li>
						</ul>
					))}
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}
