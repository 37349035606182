import CollapsibleTable	from '../complements/tables/tables_mis_reservas';





export function MisReservas(){
	if(
		localStorage.getItem('rol') === "SuperAdmin" ||
		localStorage.getItem('rol') === "Admin" ||
		localStorage.getItem('rol') === "Recepcionista" ||
		localStorage.getItem('rol') === "Usuario"){
		return (
			<>
				<h1>Mis reservas</h1>
				<div	className	= 'caja_secundaria'>
					<CollapsibleTable />
				</div>
			</>
		);
	} else {
		return (
			<>
				<div	className	= 'caja_secundaria'>
					<h3>¡NO TIENE PERMISO DE INGRESO A ESTA OPCION!</h3>
				</div>
			</>
		);
	}
}
