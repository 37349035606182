import CollapsibleTable 			from '../complements/tables/tables_reservas_salas';





export default function ReservasSalas(){
	if(
		localStorage.getItem('rol') === "SuperAdmin" ||
		localStorage.getItem('rol') === "Admin" ||
		localStorage.getItem('rol') === "Recepcionista"){
			return (
				<>
					<h1>Reservas de las Salas</h1>
					<div	className	= 'caja_secundaria'>
						<CollapsibleTable />
					</div>
				</>
			);
	} else {
		return (
			<>
				<div	className	= 'caja_secundaria'>
					<h3>¡NO TIENE PERMISO DE INGRESO A ESTA OPCION!</h3>
				</div>
			</>
		);
	}
}
