import { FormPisosModify } 			from '../forms/forms_pisos';

import Table 						from '@mui/material/Table';
import TableBody 					from '@mui/material/TableBody';
import TableCell 					from '@mui/material/TableCell';
import TableContainer 				from '@mui/material/TableContainer';
import TableHead					from '@mui/material/TableHead';
import TableRow 					from '@mui/material/TableRow';
import Paper 						from '@mui/material/Paper';
import Button						from '@mui/material/Button';
import Dialog						from '@mui/material/Dialog';
import DialogActions				from '@mui/material/DialogActions';
import DialogContent				from '@mui/material/DialogContent';
import Slide						from '@mui/material/Slide';
import { TransitionProps }			from '@mui/material/transitions';
import Skeleton						from '@mui/material/Skeleton';
import Stack						from '@mui/material/Stack';
import BottomNavigationAction		from '@mui/material/BottomNavigationAction';

import * 			as React 		from 'react';
import { useState, useEffect }		from 'react'
import { FcApproval, FcCancel}		from "react-icons/fc"
import { RiDeleteBin5Line }			from "react-icons/ri"
import { MdPublishedWithChanges }	from "react-icons/md"





export default function CollapsibleTable() {
	const [data, setData] = useState([])

	useEffect(()	=> {
			fetch(
				process.env.REACT_APP_API_URL + "/consult_floor/", {
																		'method': 'GET',
																		headers: {'Content-Type': 'applications/json'}
																	}
			)
			.then(	resp	=> resp.json())
			.then(	resp	=> {setData(resp)})
			.catch(	error	=> console.error(error))
	}, [])

	if(data.length > 0){
		return ( <TableContainer	component	= {Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell	align	= 'center'	padding	= 'checkbox'>N°</TableCell>
								<TableCell>Piso</TableCell>
								<TableCell	align	= 'center'>status</TableCell>
								<TableCell	align	= 'center'>Activar / Desactivar</TableCell>
								<TableCell	align	= 'center'>Eliminar</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{data.map((row, index)	=> (<Row key={index} index={index} row={row} />))}
						</TableBody>					</Table>
				</TableContainer>);
	} else {
		return  ( <div>
					<Stack spacing={.5}>
						<Skeleton variant="rounded"  height={40} />
						<Skeleton variant="rounded"  height={50} />
					</Stack>
					<p	className	= 'center_note'>No se ha obtenido una respuesta satisfactoria por el servidor, espere un momento por favor</p>
				</div>)
	}
}





function createData(
	piso: number,
	estado: string,
) {
	return {
		piso: piso,
		estado: estado
	};
}

function Row(props: { index:any, row: ReturnType<typeof createData> }) {
	const { row, index }	= props;
	const [data, setData]	= useState([])

	const [openModify, setOpenModify]	= React.useState(false);
	const handleClickOpenModify			= ()	=> {setOpenModify(true);};
	const handleCloseModify				= ()	=> {setOpenModify(false);};

	const [openDelete, setOpenDelete]	= React.useState(false);
	const handleClickOpenDelete			= ()	=> {setOpenDelete(true);};
	const handleCloseDelete				= ()	=> {setOpenDelete(false);};

	const handlesend				= async ()	=> {
		fetch(
			process.env.REACT_APP_API_URL + "/delete_floor/", {
																	'method': 'DELETE',
																	headers: {'Content-Type': 'applications/json'},
																	body: JSON.stringify(row)
																}
		)
		.then(	resp	=> resp.json())
		.then(	resp	=> {setData(resp)})
		.catch(	error	=> console.error(error))
		.finally(refreshpage)
	};

	return (
		<React.Fragment>
			<TableRow	sx	= {{ '& > *': { borderBottom: 'unset' } }}>
				<TableCell	align	= 'center'	padding	= 'checkbox'>{index + 1}</TableCell>
				<TableCell>{row.piso}</TableCell>
				<TableCell	align	= 'center'	padding	= 'checkbox'>{row.estado === "True" ? <FcApproval /> : <FcCancel />}</TableCell>
				<TableCell	align	= 'center'	padding	= 'checkbox'><BottomNavigationAction icon = {<MdPublishedWithChanges />}	onClick	= {handleClickOpenModify}/></TableCell>
				<TableCell	align	= 'center'	padding	= 'checkbox'><BottomNavigationAction icon = {<RiDeleteBin5Line />}			onClick	= {handleClickOpenDelete}/></TableCell>
			</TableRow>

			<Dialog
				open				= {openDelete}
				TransitionComponent	= {Transition}
				keepMounted
				onClose				= {handleCloseDelete}
				aria-describedby	= "alert-dialog-slide-description"
			>
				<div	className	= 'SuperDialog'>
					<h4>¿Desea eliminar <span>{row.piso}</span> como opción?</ h4>

					<DialogActions>
						<Button	onClick	= {handlesend}>Eliminar</Button>
						<Button	onClick	= {handleCloseDelete}	className	= "cancel">x</Button>
					</DialogActions>
				</div>
			</Dialog>

			<Dialog
				open				= {openModify}
				TransitionComponent	= {Transition}
				keepMounted
				onClose				= {handleCloseModify}
				aria-describedby	= "alert-dialog-slide-description"
			>
				<h4>¿Desea modificar el estado del piso  <span>{row.piso}</span> ?</ h4>
				<DialogContent><FormPisosModify piso	= {row.piso} estado	= {row.estado}/></DialogContent>
				<DialogActions><Button onClick	= {handleCloseModify}	className	= "cancel">x</Button></DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

const Transition = React.forwardRef(function Transition(props: TransitionProps & {children: React.ReactElement<any, any>;}, ref: React.Ref<unknown>, ) {
	return <Slide	direction	= "up"	ref	= {ref} {...props} />;
});

const	refreshpage	= ()	=> {window.location.reload()}