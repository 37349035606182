import * as React 					from 'react';

import Box							from '@mui/material/Box';
import BottomNavigation				from '@mui/material/BottomNavigation';
import BottomNavigationAction		from '@mui/material/BottomNavigationAction';
import Paper						from '@mui/material/Paper';

import BusinessIcon					from '@mui/icons-material/Business';
import DeskIcon						from '@mui/icons-material/Desk';
import LocalParkingIcon				from '@mui/icons-material/LocalParking';
import ManageAccounts				from '@mui/icons-material/ManageAccounts';

import PermisosUsuarios				from './admin/admin_permisos_usuarios';
import Parqueadero					from './admin/admin_parqueadero';
import Pisos						from './admin/admin_pisos';
import Puestos						from './admin/admin_puestos';





export default function Configuracion() {
	const [value, setValue]	= React.useState(0);
	const ref				= React.useRef<HTMLDivElement>(null);

	if(
		localStorage.getItem('rol') === "SuperAdmin" ||
		localStorage.getItem('rol') === "Admin"){
		return (
			<>
				<h1>Configuración</h1>
	
				<Box ref	= {ref}	className	= 'MenuTop' >
					<Paper elevation	= {2}>
						<BottomNavigation showLabels value	= {value} onChange	= {(e, newValue)	=> {setValue(newValue);}}>
							<BottomNavigationAction	label	= "Pisos del edificio"			value	= {1}	icon	= {<BusinessIcon />}/>
							<BottomNavigationAction	label	= "Puestos de trabajo"			value	= {2}	icon	= {<DeskIcon />} />
							<BottomNavigationAction	label	= "Parqueadero del edificio"	value	= {3}	icon	= {<LocalParkingIcon />} />
							<BottomNavigationAction	label	= "Permisos de usuario"			value	= {4}	icon	= {<ManageAccounts />} />
						</BottomNavigation>
					</Paper>
				</Box>
	
				{(()	=> {
					switch (value) {
						case 1:		return (<Pisos />)
						case 2:		return (<Puestos />)
						case 3:		return (<Parqueadero />)
						case 4:		return (<PermisosUsuarios />)
						default:	return (
												<div	className	= 'caja_secundaria'>
													<p>Seleccione su requerimiento de administración</p>
												</div>
											)
					}
				})()}
			</>
		);
	} else {
		return (
			<>
				<div	className	= 'caja_secundaria'>
					<h3>¡NO TIENE PERMISO DE INGRESO A ESTA OPCION!</h3>
				</div>
			</>
		);
	}
}